import React from 'react';
import Button from 'components/Button';
import SaveExit from 'components/SaveExit';
import SubmitModal from 'components/SubmitModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import styles from 'features/vehicleDetails/consignModal/consignModal.module.scss';
import { BackArrow } from '../../../../public/svg/BackArrow';
const Footer = ({
  step,
  handleBack,
  handleNext,
  isValid,
  dirty,
  submit,
  showSubmitModal,
  closeSubmitModal,
  isSubmitting,
  touched,
  consignmentSlug,
  buttonName,
  modalClass,
}) => {
  let title = 'Are you sure you want to leave the cosign inquiry?';
  let content =
    'By leaving this page, your consignment information will be lost.';
  let link = consignmentSlug;

  let submitTitle = 'SUBMITTED';
  let submitContent =
    'Thank you for your submission. A Gooding representative will be reaching out to connect.';

  return (
    <div className={buttonName ? styles.formFooterModal : 'form-footer'}>
      {step === 1 ? (
        <Button
          type={`app-transparent-blue-button ${
            buttonName && styles.backButton
          }`}
          handleClick={handleBack}
        >
          {buttonName ? (
            <BackArrow />
          ) : (
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
          )}
          Back
        </Button>
      ) : (
        step === 1 &&
        buttonName === undefined && (
          <div className="flex-wrapper">
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            <SaveExit
              title={title}
              content={content}
              link={link}
              buttonName={buttonName || 'Back'}
            />
          </div>
        )
      )}
      <div
        className={`flex-wrapper ${
          buttonName && styles.flexWrapperModal
        } w-100 justify-content-end`}
      >
        {!buttonName && (
          <SaveExit
            title={title}
            content={content}
            link={link}
            buttonName={buttonName || 'Exit'}
          />
        )}
        {step === 1 ? (
          <SubmitModal
            submitTitle={submitTitle}
            submitContent={submitContent}
            isValid={isValid}
            submit={submit}
            isSubmitting={isSubmitting}
            touched={touched}
            buttonName={buttonName}
            // showSubmitModal={showSubmitModal}
            // closeSubmitModal={closeSubmitModal}
          />
        ) : (
          <Button
            type={` ${buttonName && styles.appPrimary} ${
              isValid ? 'app-primary-button' : ' app-primary-button inactive'
            }`}
            handleClick={handleNext}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default Footer;
